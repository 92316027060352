.App {
  text-align: center;
  font-family: "Jersey 20", sans-serif;
  font-weight: 400;
  font-style: normal;
  width: 100%;
}

.container {
  background: linear-gradient(135.05deg, #f8f5ec .05%, #f5ebcd 99.96%);
}

.container .first-m {
  display: flex;
  justify-content: center;
}

.container .first-m .lama {
  position: relative;
  width: 100%;
}

.container .first-b {
  padding: 2rem;
  display: flex;
  justify-content: center;
}

.line {
  height: auto;       /* 保持图片的纵横比 */
  display: block;     /* 去除底部空隙 */
}

.mint-times {
  color: #f0553a;
}

.header {
  background-color: #f5f5f5;
  width: 90%;
  padding: 20px;
}

.logo {
  width: 15rem;
}

.middle-empty {
  flex-grow: 1;
}

.main {
  display: flex;
  flex-direction: column;
}

.buy-section {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 20px;
}

.buy-button {
  padding: 10px 20px;
  background-color: #008CBA;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  margin-top: 10px;
}

.cool-section {
  display: flex;
  justify-content: center;
  align-items: center;
}

.container .first-top {
  display: flex;
  flex-direction: column; /* 使其在小屏幕上垂直排列 */
  align-items: center;    /* 中心对齐 */
  padding: 2rem;         /* 增加一些padding */
}

.connect-btn {
  font-size: 2rem;
  background-color: #fff;
  padding: 1rem 2rem;
  border-radius: 10rem;
  border: 2px solid #000;
  color: #000;
  cursor: pointer;
  position: relative;
  display: flex;
  align-items: center;
}

.connect-btn-small{
  font-size: 1rem;
  background-color: #f1da57;
  padding: .5rem 1rem;
  border-radius: 10rem;
  border: 2px solid #000;
  color: #000;
  cursor: pointer;
  position: relative;
  display: flex;
  align-items: center;
}

.container .first-top .connect-btn img {
  width: 34rem;
  margin-right: 8rem;
}

.container .first-top .connect-btn .popover {
  position: absolute;
  left: 0;
  top: 110rem;
  padding-top: 10px;
  background-color: #fff;
  border-radius: 24rem;
  box-shadow: 0 0 4rem 8rem #0004;
  cursor: default;
}

.container .first-top .connect-btn .popover .address {
  font-size: 36rem;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10rem 20rem;
}

.container .first-top .connect-btn .popover .address svg {
  cursor: pointer;
  margin-left: 10rem;
}

.container .first-top .connect-btn .popover .logout {
  border-top: 1px solid #000;
  text-align: center;
  padding: 16rem;
}

.mint-btn {
  font-size: 2rem;
  background-color: #fff;
  padding: 1rem 2rem;
  border-radius: 10rem;
  border: 2px solid #000;
  color: #000;
  cursor: pointer;
  position: relative;
  display: flex;
  align-items: center;
}

.top-bar {
  display: flex;
  justify-content: center; /* 在主轴方向上居中对齐 */
  align-items: center;     /* 在交叉轴方向上垂直居中 */
  width: 100%;            /* 让 top-bar 占满整个宽度 */
  padding-top: 20px;          /* 适合小屏幕的padding */
}

.top-bar-content {
  display: flex;
  justify-content: space-between; /* 在行内均匀分布 logo 和按钮 */
  align-items: center;            /* 垂直居中 */
  width: 90%;                     /* 设置宽度为 90% */
  max-width: 1200px;             /* 可选：限制最大宽度 */
  padding-top: 40px;
}

.logo {
  width: 15rem; /* 或其他合适的宽度 */
}

.connect-btn {
  font-size: 2rem;
  background-color: #fff;
  padding: 1rem 2rem;
  border-radius: 1rem;
  border: 2px solid #000;
  color: #000;
  cursor: pointer;
  margin-left: auto; /* 将按钮推到右侧 */
}

.social{
  padding:1rem
}

/* 网页中央卡片样式 */
.container-card {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 20vh;
  width: 1000px;
  padding-top: 25px;
  margin: 0 auto;
}

.card {
  background-color: #ffffff;
  border-radius: 1rem;
  /* border: 0.2em solid #010100; */
  box-shadow: 0 4px 16px rgba(0, 0, 0, 0.1);
  padding: 48px;
  color: #0d0c0c;
  width: 100%;
  max-width: 1000px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
}

/* 卡片标题样式 */
.card-title {
  text-align: center;
  color:#dc9f62;
  font-weight: bolder;
  font-size: 40px;
}

/* 卡片标题样式 */
.card-title-num {
  color:rgb(230, 86, 86);
  font-weight: bolder;
  font-size: 5rem;
  position: relative;    /* 设置为相对定位，以便绝对定位的子元素可以相对于它定位 */
  cursor: pointer;
}

.card-title-num.explode {
  animation: explode 0.5s forwards; /* 添加动画 */
}

/* fireworks */
@keyframes explode {
  0% {
    transform: scale(1);
    opacity: 1;
  }
  50% {
    transform: scale(1.5);
    opacity: 0.5;
  }
  100% {
    transform: scale(0);
    opacity: 0;
  }
}

/* 卡片标题样式 */
.card-sec-title {
  color:rgb(230, 86, 86);
  font-weight: bolder;
  font-size: 2rem;
}

/* 卡片网格布局 */
.card-grid {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.divider.vertical-divider {
  width: 1px;
  background-color: #ccc;
  align-self: stretch;
}

/* 按钮组样式 */
.button-group {
  display: flex;
  justify-content: center;
  gap:10px;
  margin-top: 16px;
}

/* 按钮样式 */
.approve-button, .start-button {
  padding: 12px 24px;
  border-radius: 8px;
  cursor: pointer;
  color: #fff;
  border: none;
  margin: 0 8px;
}

.approve-button {
  background-color: #27ae60;
}

.start-button {
  background-color: #eb5757;
}

.button-group a {
  display: inline-block; /* 使链接像块级元素一样显示 */
  padding: 8px 12px;     /* 设置内边距，增加点击区域 */
  border: 2px solid #000; /* 设置边框颜色和宽度 */
  border-radius: 8px;    /* 设置圆弧边框半径 */
  text-decoration: none; /* 移除默认的下划线 */
  color: #000;           /* 设置文字颜色 */
  background-color: #fff; /* 设置背景颜色 */
  transition: background-color 0.3s, color 0.3s, border-color 0.3s;
}

.button-group a:hover {
  background: linear-gradient(90deg, #8ab7f5, #0af0b3); /* 渐变背景 */
  color: #fff; /* 字体颜色变为白色 */
  border-color: #16b10b; /* 边框颜色变化 */
}

.card-block {
  padding: 16px;            /* 添加一些内边距 */
  /* background: linear-gradient(90.05deg, #f1da57 .05%, #dc9f62 99.96%);
  /* border: 1px solid #ddd;    /* 边框样式 */
  border-radius: 8px;        /* 圆角边框 */
}

.card-span {
  text-align: left;
  font-size: 1rem;
}

.divider {
  height: 1px;               /* 垂直分隔线 */
  background-color: #ccc;    /* 分隔线颜色 */
  margin: 16px 0;            /* 上下边距 */
}

.divider-h {
  height: 1px;               /* 水平分隔线 */
  background-color: #ccc;    /* 分隔线颜色 */
  margin: 16px 0;            /* 上下边距 */
}


.card-container {
  display: flex;              /* 使用 Flexbox 布局 */
  flex-direction: column;     /* 垂直排列 */
  gap: 16px;                 /* 两个卡片之间的间距 */
  padding-top: 2rem;
  padding-bottom: 2rem;
}

.card-left, .card-right {
  background: linear-gradient(90.05deg, #ffffff .05%, #ffffff 99.96%);
  padding: 16px;             /* 内边距 */
  background-color: #f9f9f9; /* 背景颜色 */
  border: 1px solid #ddd;    /* 边框 */
  border-radius: 8px;        /* 圆角边框 */
}

.info-grid {
  display: grid;             /* 使用网格布局 */
  grid-template-columns: repeat(2, 1fr); /* 两列 */
  gap: 16px;                /* 列间距 */
  margin-bottom: 16px;      /* 与输入组之间的间距 */
}

.info-grid p {
  margin: 0;                /* 去掉段落的默认外边距 */
  font-size: 2rem;          /* 增大字体大小 */
}

.input-group {
  display: flex;            /* 使用 Flexbox 布局 */
  align-items: center;      /* 垂直居中对齐 */
  justify-content: center;  /* 水平居中对齐 */
  gap: 10px;                /* 元素间距 */
  margin-bottom: 16px;      /* 与 PlayNow 按钮之间的间距 */
}

/* 按钮样式 */
.max-button, .approve-button, .start-button {
  padding: 10px 14px;      /* 按钮内边距 */
  border: none;            /* 去掉按钮边框 */
  border-radius: 4px;      /* 圆角按钮 */
  background-color: #4CAF50; /* 按钮背景色 */
  color: white;            /* 按钮文本颜色 */
  cursor: pointer;          /* 鼠标指针 */
  width: 30%;             /* 按钮宽度为100% */
}

.playnow-button {
  width: 30%;
  font-size: 2rem;
  background-color: #ffffff;
  padding: 1rem 2rem;
  border-radius: 1rem;
  border: 2px solid #000;
  color: #000;
  cursor: pointer;
  position: relative;
  align-items: center;
  margin: 10px;
  transition: background-color 0.3s, color 0.3s, border-color 0.3s;
}

/* 鼠标悬停时的样式 */
.playnow-button:hover {
  background: linear-gradient(90deg, #f6f7eb, #dcf1ec); /* 渐变背景 */
  color: #fff; /* 字体颜色变为白色 */
  border-color: #ffffff; /* 边框颜色变化 */
}


.playnow-button-padding{
  padding:10px
}

.how-to-play {
  padding-top: 100px;
  padding-bottom: 25px;
  font-size: 2rem;
  cursor: pointer;
}

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

.modal-content {
  position: relative;
  padding: 20px;
  width: 80%;
  max-width: 500px;
  border-radius: 8px;
  text-align: left;
  color: #333;
  font-size: 14px;
  overflow: hidden;
}

.modal-content::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: url('/public/assets/howtoplay.jpg') center center/cover no-repeat;
  opacity: 0.9; /* 仅图片的透明度 */
  z-index: -1; /* 确保文字显示在图片上方 */
  border-radius: 8px;
}

.modal-content span {
  display: block;
  margin-bottom: 10px;
  font-size: 2rem; /* 增大字体大小 */
}

/* 悬停效果 */
.max-button:hover, .approve-button:hover, .start-button:hover {
  background-color: #45a049; /* 悬停时的背景颜色 */
}

/* Claim 按钮单独容器 */
.claim-button-container {
  margin-top: 10px;        /* 添加顶部间距 */
}

/* 定义熊猫容器和熊猫图片样式 */
.panda-animation {
  position: fixed;
  width: 100px; /* 熊猫图像的大小 */
  height: 100px;
  z-index: 1000; /* 保证熊猫在最上方 */
  pointer-events: none; /* 不影响其他按钮的点击 */
}

.panda {
  width: 100%;
  height: 100%;
}

/* 媒体查询 */
@media (max-width: 768px) { /* 平板和手机屏幕 */
  .logo {
    width: 10rem; /* 小屏幕上缩小logo */
  }

  .connect-btn {
      font-size: 1.5rem; /* 调整按钮字体大小 */
      padding: 0.5rem 1rem; /* 调整按钮的padding */
  }

  .first-m {
      flex-direction: column; /* 小屏幕上竖向排列 */
  }

  .first-m .lama img {
      width: 100%; /* 确保图像在小屏幕上自适应宽度 */
      height: auto; /* 保持比例 */
  }

  .mint-times {
      font-size: 1.5rem; /* 调整字体大小 */
  }

  .mint-btn {
      font-size: 1.5rem; /* 调整按钮字体大小 */
      padding: 0.5rem 1rem; /* 调整按钮的padding */
  }

  .line {
      width: 100%; /* 使line在小屏幕上宽度100% */
  }

  .container-card {
    width: 95%;
    padding-top: 25px;
  }

  .card {
    width: 80%;
    padding: 16px;
  }

  .approve-button, .start-button {
    width: 100%;
    margin: 8px 0;
  }

  .card-grid {
    flex-direction: column;
  }

  .card-grid {
    flex-direction: column;   /* 垂直排列 */
  }

  .info-grid {
    grid-template-columns: 1fr; /* 列数调整为1 */
  }

  .start-button, .approve-button {
    width: 100%;            /* 按钮宽度为100% */
    margin-top: 10px;       /* 添加顶部间距 */
  }

  .playnow-button {
    width: 80%;           /* 在小屏幕上增加按钮宽度 */
    font-size: 0.9rem;    /* 调整字体大小 */
    margin:10px
  }

  .modal-content {
    width: 80%;
    padding: 15px;
    font-size: 13px;
  }

  .modal-content span {
    font-size: 20px; /* 移动端的字体大小 */
  }
}
